import { useMemo } from 'react';

import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { Container } from 'components/Container';
import { BackButton } from 'components/Partners';
import { Box } from 'components/Box';
import { HelpLink } from 'components/Links';
import { List } from 'components/List';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';
import type { HelpCategoryPages } from 'types/help/helpCategory';

const HelpCategory: FC<
  LocalizedPageProps & {
    pageContext: {
      helpCategory: string;
      helpCategoryPages: HelpCategoryPages[];
    };
  }
> = ({ pageContext }) => {
  const helpLinks = useMemo(
    () =>
      pageContext.helpCategoryPages.map(helpCategory => ({
        id: helpCategory.url_slug,
        content: (
          <HelpLink
            key={helpCategory.url_slug}
            to={helpCategory.static_url_slug || helpCategory.url_slug}
          >
            {helpCategory.title.title}
          </HelpLink>
        ),
      })),
    [pageContext.helpCategoryPages]
  );

  return (
    <Layout>
      <Seo />
      <Container background="navyBlueHighlight" manageLayout={false}>
        {/* TODO: once the help site is deprecated, this should change to HelpLink, 
        and HelpLink should be updated to link to help pages in static-www */}
        <BackButton
          asButton
          to="/help"
          title="Help | Sharesight"
          appearance="tertiary"
          showArrowIcon
          iconDirection="left"
        >
          Back to help centre
        </BackButton>
        <Box paddingTop={10}>
          <h1>{pageContext.helpCategory}</h1>
        </Box>
      </Container>
      <Container manageLayout={false}>
        <List items={helpLinks} isBulleted={false} />
      </Container>
    </Layout>
  );
};

export default HelpCategory;
